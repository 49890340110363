import { createContext, useEffect, useState } from 'react';
import getChannelsByChildId from 'services/getChannelsByChildId';
import { Channel } from 'types';

export const ChannelContext = createContext<Channel[] | null>(null);

const ChannelsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [channels, setChannels] = useState<Channel[] | null>(null);

  const fetchChannels = async (childId: string) => {
    const channels: Channel[] | [] = await getChannelsByChildId(childId);

    setChannels(channels);
  };

  useEffect(() => {
    fetchChannels('123456');
  }, []);

  return <ChannelContext.Provider value={channels}>{children}</ChannelContext.Provider>;
};

export default ChannelsContextProvider;
