import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import ChannelListContainer from 'components/ChannelListContainer';
import { useUser } from 'contexts/userContext';
import getChannelsByChildId from 'services/getChannelsByChildId';
import { Channel } from 'types';
import { ChannelContext } from 'contexts/ChannelsContextProvider';

const ChannelList = () => {
  const { user } = useUser();
  const channels = useContext(ChannelContext) || [];

  return (
    <>
      <Typography
        variant="h5"
        sx={{ paddingTop: '48px', width: '100%', textAlign: 'center', marginTop: '20px' }}
      >
        Canales
      </Typography>
      {channels.length > 0 && <ChannelListContainer channels={channels} />}
    </>
  );
};

export default ChannelList;
