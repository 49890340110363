import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Channel } from 'types';

const getChannelsByChildId = async (childId: string): Promise<Channel[]> => {
  try {
    const safe_youtube_db = getFirestore();

    // 1. Obtener todos los documentos de channel_child
    const channelChildCollection = collection(safe_youtube_db, 'channel_child');
    const channelChildSnapshot = await getDocs(channelChildCollection);

    // Filtrar localmente los channelIds que coinciden con el childId
    const channelIds = channelChildSnapshot.docs
      .map((doc) => doc.data())
      .filter((data) => data.childId === childId) // Filtrar por childId
      .map((data) => data.channelId);

    // Si no hay channelIds asociados, devolvemos un array vacío
    if (channelIds.length === 0) {
      return [];
    }

    // 2. Obtener todos los documentos de channels
    const channelsCollection = collection(safe_youtube_db, 'channels');
    const channelsSnapshot = await getDocs(channelsCollection);

    // Filtrar localmente los canales que coinciden con los channelIds
    const channels = channelsSnapshot.docs
      .map((doc) => doc.data())
      .filter((channel) => channelIds.includes(channel.channelId)) // Filtrar por channelId
      .map((data) => ({
        channelId: data.channelId,
        name: data.name,
        thumbnail: data.thumbnail,
      }));

    return channels;
  } catch (error) {
    console.error('Error al obtener los canales:', error);
    return [];
  }
};

export default getChannelsByChildId;
