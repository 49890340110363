import { CircularProgress } from '@mui/material';
import { ChannelContext } from 'contexts/ChannelsContextProvider';
import { useContext } from 'react';

const DataNeededGuard = ({ children }: { children: React.ReactNode }) => {
  const channels = useContext(ChannelContext);

  if (!channels) {
    return <CircularProgress />;
  }

  return <>{children}</>;
};

export default DataNeededGuard;
